
import React from 'react';

const Footer = ({ legalInfo, contactInfo }) => {
  return (
    <footer id="footer" className="footer-container w3-container w3-padding-64 w3-center w3-opacity">
      <p>Prevádzkovateľ: Jawolta s.r.o</p>
      <p>IČO: 47 472 065</p>
      <p>podpora@dobierkomat.com</p>
      <p><a href="https://wa.me/421944749525">+421944749525</a></p>
      <p>Zákaznícka podpora v pracovné dni od 10:00 - 16:00</p>
    </footer>
  );
};

export default Footer;
