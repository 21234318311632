
import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom"; 



function toggleMenu() {
  console.log("toggleMenu");
  var x = document.getElementById("navDemo");
  if (x.className.indexOf("w3-show") === -1) {
    x.className += " w3-show";
  } else { 
    x.className = x.className.replace(" w3-show", "");
  }
}

const NavBar = () => {
  // Close the dropdown menu if the user clicks outside of it
  const navRef = useRef(null);  
  useEffect(() => {
    const handleClickOutside = (event) => {   
      if (navRef.current && !navRef.current.contains(event.target)) {
        var x = document.getElementById("navDemo");
        if (x && x.className.indexOf("w3-show") !== -1) {
          x.className = x.className.replace(" w3-show", "");
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div id="menu" ref={navRef} className="w3-top">
      <div className="w3-bar custom-color w3-card w3-left-align w3-large">
          <div className="w3-bar-item w3-button w3-hide-medium w3-hide-large w3-right w3-padding-large w3-hover-white w3-large custom-color"  onClick={toggleMenu} title="Toggle Navigation Menu"><i className="fa fa-bars"></i></div>                   
            <Link to="/" className="w3-bar-item w3-button w3-padding-large w3-white">Domov</Link> {/* Points to home */}
            <Link to="/documentation" className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">Ako to funguje?</Link> {/* Points to Documentation component */}
            <Link to="/payee" className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">Predávam</Link> {/* Points to Payee component */}
            <Link to="/payer" className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">Nakupujem</Link> {/* Points to Payer component */}
            <Link to="/footer" className="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">Kontakt</Link> {/* Points to Footer or Contact component */}          
        </div>
        <div id="navDemo" className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium w3-large">
          <Link to="/documentation" className="w3-bar-item w3-button w3-padding-large">Ako to funguje?</Link> {/* Points to Documentation component */}
          <Link to="/payee" className="w3-bar-item w3-button w3-padding-large">Predávam</Link> {/* Points to Payee component */}
          <Link to="/payer" className="w3-bar-item w3-button w3-padding-large">Nakupujem</Link> {/* Points to Payer component */}
          <Link to="/footer" className="w3-bar-item w3-button w3-padding-large">Kontakt</Link> {/* Points to Footer or Contact component */}
        </div>
    </div>
  );
};

export default NavBar;
