import React, { useEffect, useState } from 'react';
/*import { useNavigate } from 'react-router-dom';*/

const Payee = () => {
  const [dobierkaData, setDobierkaData] = useState(null);
  const [error, setError] = useState(null);
  const [dobierkaId, setDobierkaId] = useState('');
  
  const handleIBAN = async (event) => {
    event.preventDefault();
    const accountIban = event.target.account_iban.value;

    try {
      const response = await fetch('/api/iban', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account_iban: accountIban, dobierka_id: dobierkaData.client_reference_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setError(null);
        setDobierkaData(data)
        console.log(dobierkaData)
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Nepodarilo sa uložiť IBAN. Skúste to znova, alebo kontaktujte zákaznícku podporu.');
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dobierkaId = event.target.dobierka_id.value;

    try {
      const response = await fetch('/api/claim', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dobierka_id: dobierkaId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        setDobierkaData(null);
      } else {
        setDobierkaData(data);
        setError(null);
      }
    } catch (error) {
      setError('Toto číslo dobierky sme u nás nenašli.');
      setDobierkaData(null);
    }
  };

    /*const navigate = useNavigate();*/
   useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const dobierkaIdParam = params.get('payee_dobierka_id');
        console.log(dobierkaIdParam);
        var userInputDobierkaId = document.getElementById("userInputDobierkaId");
        if (dobierkaIdParam) {
          /* hide form Vydvihni if autosubmit */
          userInputDobierkaId.className = userInputDobierkaId.className.replace(" w3-show", "");
          setDobierkaId(dobierkaIdParam);
          const fakeEvent = {
            preventDefault: () => {},
            target: {
              dobierka_id: {
                value: dobierkaIdParam
              }
            }
          };
          handleSubmit(fakeEvent);          
        }
        else {
          userInputDobierkaId.className += " w3-show";
        }
      }, [dobierkaId]);

  return (
    <div id="payee" className="w3-row-padding w3-padding-64 w3-container">
      <div className="w3-content">
        <div className="w3-twothird">
          <h1>Prevziať vklad</h1>
          <h5 className="w3-padding-32">
            <ol>
              <li>Over, či kupujúci vložil peniaze v tvoj prospech.</li>
              <li>Zadaj svoje číslo účtu v tvare IBAN, kam ti má byť vyplatená suma dobierky. <div className="w3-text-red">Dobierkomat od predávajúcich nikdy nevyžaduje číslo platobnej karty.</div></li>
            </ol>
          </h5>
          <form id="userInputDobierkaId" className="product-form w3-hide" onSubmit={handleSubmit}>
            <div  className="form-group" style={{ marginBottom: '16px' }}>
              <label htmlFor="dobierka_id" className="w3-left w3-hide">Číslo dobierky:</label>
              <input
                id="dobierka_id"
                name="dobierka_id"
                value={dobierkaId}
                onChange={(e) => setDobierkaId(e.target.value)}
                className="w3-input w3-border w3-padding"
                required
              />
            </div>
            <button id="buttonVyzdvihni" className="w3-button w3-black w3-padding-large w3-large w3-margin-top" type="submit">
            Vyzdvihni dobierku
            </button>
          </form>
          <p></p>
          {dobierkaData && dobierkaData.client_reference_id && dobierkaData.status === "new" && (
            <form className="iban-form" onSubmit={handleIBAN}>
              <div className="form-group" style={{ marginBottom: '16px' }}>
                <label htmlFor="account_iban" className="w3-left">Číslo účtu IBAN:</label>
                <input
                  type="text"
                  id="account_iban"
                  name="account_iban"
                  className="w3-input w3-border w3-padding"
                  required
                />
              </div>
              <button className="w3-button w3-black w3-padding-large w3-large w3-margin-top" type="submit">
                Ulož
              </button>
            </form>
          )}
          {dobierkaData && (
            <div className="w3-panel custom-color w3-padding-16" style={{ marginTop: '16px' }}>
              <h4>Evidujeme vklad v prospech {dobierkaData.payee_email}</h4>
              <p>Číslo dobierky: {dobierkaData.client_reference_id}</p>
              <p>Stav: {dobierkaData.status}</p>
              <p>IBAN: {dobierkaData.iban}</p>
              <p>Email kupujúceho: {dobierkaData.payer_email}</p>
              <p>Suma: {dobierkaData.amount} {dobierkaData.currency}</p>
              <p>Názov tovaru: {dobierkaData.productItem}</p>              
              <p>Poznámka: {dobierkaData.additional_info}</p>
            </div>
          )}
          {error && (
            <div className="w3-panel custom-color2 w3-padding-16" style={{ marginTop: '16px' }}>
              <p>{error}</p>
            </div>
          )}
        </div>
        <div className="w3-third w3-center">
          <i className="fa fa-user w3-padding-64 custom-text-color"></i>
        </div>
      </div>
    </div>
  );
};

export default Payee;
