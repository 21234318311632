// src/components/Documentation.js
import React from 'react';
// import '../styles/Documentation.css';

const Documentation = () => {
  return (
   <div id="how-it-works" className="w3-row-padding w3-light-grey w3-padding-64 w3-container">  
      <div className="w3-content">
        <div className="w3-third w3-center">
          <i className="fa fa-gear w3-padding-64 custom-text-color w3-margin-right"></i>
        </div>        
        <h1>Ako to funguje</h1>
        <h5 className="w3-padding-32">Dobierkomat poskytuje ochranu kupujúcemu aj predávajúcemu.</h5>
        <p className="w3-text-grey">S Dobierkomatom je tvoja platba v bezpečí. Neplať vopred predávajúcemu za tovar. Koniec neprevzatým dobierkam.</p>  
        <p className="w3-text-grey"><li>Nájdi si na e-bazároch tovar, ktorý si chceš zakúpiť</li>
<li>Oslov predávajúceho a ponúkni mu platbu cez Dobierkomat</li>
<li>Dohodni sa na cene vrátane poštovného a vypýtaj si jeho mail.</li> 
<li>Vlož peniaze predávajúcemu bezpečne do Dobierkomatu.</li> 
<li>Uisti sa či ti došlo to, čo si si objednal a potvrď transakciu.</li>
</p>  
      </div> 
    </div>
  );
};

export default Documentation;
