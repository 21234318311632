// src/components/Payer.js
import React from 'react';
import '../styles/Payer.css';

const Payer = ({ text }) => {
  return (
    <div id="payer" className="w3-row-padding w3-light-grey w3-padding-64 w3-container">
      <div className="w3-content">
        <div className="w3-third w3-center">
          <i className="fa fa-coffee w3-padding-64 custom-text-color w3-margin-right"></i>
        </div>
        <div className="w3-twothird">      
          <p className={`message-text w3-gray w3-padding-large w3-container w3-center ${text ? 'visible' : 'hidden'}`}>
            {text}
          </p>
          <p></p>
          <h1>Nová dobierka</h1>   
          <h5 className="w3-padding-32">Vlož peniaze do Dobierkomatu v prospech predávajúceho. Tvoje peniaze sú v bezpečí, až kým si neprevezmeš tovar.</h5>
          <form className="product-form" action="/api/create-checkout-session" method="POST">
            <div className="form-group" style={{ marginBottom: '16px' }}>
              <label htmlFor="payer_email" className="w3-left">Email kupujúceho</label>
              <input
                type="email"
                id="payer_email"
                name="payer_email"
                className="w3-input w3-border w3-padding"
                required
              />
              <label htmlFor="payee_email" className="w3-left">Email predávajúceho</label>
              <input
                type="email"
                id="payee_email"
                name="payee_email"
                className="w3-input w3-border w3-padding"
                required
              />
              <label htmlFor="amount" className="w3-left">Suma dobierky (€)</label>
              <input
                type="number"
                id="amount"
                name="amount"
                className="w3-input w3-border w3-padding"
                step="0.1"
                required
              />
              <label htmlFor="product_item" className="w3-left">Názov tovaru</label>
              <input
                type="text"
                id="product_item"
                name="product_item"
                className="w3-input w3-border w3-padding"
                required
              />
              <label htmlFor="additional_info" className="w3-left">Poznámka:</label>
              <p>
                <textarea className="w3-input w3-border" id="additional_info" name="additional_info"></textarea>
              </p>
              <p>Po uskutočnení platby má predávajúci maximálne 7 dní na odoslanie tovaru a 14 dní na jeho doručenie. V prípade akéhokoľvek omeškania alebo nedodržania týchto lehôt, prosím, okamžite kontaktujte naše <a href="/footer">zákaznícke centrum</a>.
              </p>
            </div>
            <button className="w3-button w3-black w3-padding-large w3-large w3-margin-top" type="submit">
              Vlož
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Payer;
