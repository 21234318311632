// src/components/Header.js
import React from 'react';
import { Link } from "react-router-dom"; 


const Header = () => (

  //  <a href="#payer" className="w3-button w3-black w3-padding-large w3-large w3-margin-top">Vytvor dobierku</a> }


<header id="header" className="w3-container custom-color w3-center" style={{ padding: '128px 16px' }}>
<h1 className="w3-margin">Dobierkomat</h1>
<p className="w3-xlarge">Pre bezpečné nákupy</p>
<Link to="/payer" className="w3-button w3-black w3-padding-large w3-large w3-margin-top">Vytvor dobierku</Link> {/* Points to Footer or Contact component */}
</header>
);

export default Header;